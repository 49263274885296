import React from "react";
import { FavoriteListCard, ListItem } from "~/shared";
import useLocalStorageState from "use-local-storage-state";
import { copyToClipBoard } from "~/utils";
import { getClientSideConfig } from "~/utils";
import toast, { Toaster } from "react-hot-toast";
// import { createBookmarktList } from "~/api";
import { useNavigate } from "@remix-run/react";

const FavoriteList: React.FC<{ setFavOpen: (isOpen: boolean) => void }> = ({
  setFavOpen,
}) => {
  const [favoriteList, setFavoriteList] = useLocalStorageState("favoriteList", {
    defaultValue: [],
  });

  const navigate = useNavigate();

  const onOpen = (text: string) => {
    return toast.success(text);
  };

  const removeFromFavorit = (id: number) => {
    // @ts-ignore
    const newItems = favoriteList.filter((item: any) => item.id !== id);
    if (newItems) {
      setFavoriteList(newItems);
    }
  };
  const handleCopyArticleToClipboard = (id: number, name: string) => {
    const isCopied = copyToClipBoard(
      `${getClientSideConfig.SHARELINKURL}/search/main/${id}/${name.replace(
        /\s/g,
        "-"
      )}}`
    );

    if (isCopied) {
      onOpen(
        "Der Link zu diesem Eintrag befindet sich nun in Deiner Zwischenablage. Du kannst ihn nun überall über Rechtsklick+Einfügen oder strg+v/cmd+v  einfügen."
      );
    }
    setTimeout(() => {
      setFavOpen(false);
    }, 9000);
  };
  const handleOpenFavoriteListItem = (id: number, name: string) => {
    if (id && name) {
      navigate(`/search/main/${id}/${name.replace(/\s/g, "-")}`);

      setTimeout(() => {
        setFavOpen(false);
      }, 1000);
    }
  };

  return (
    <div
      className="border-t border-gray-200 px-2"
      // style={{
      //   paddingRight: 5,
      //   paddingBottom: 9,
      // }}
    >
      {/* <div className="h-1 w-full bg-black" /> */}

      <div
        className="scrollbar-styled"
        // margin={{ top: '15px' }}
        // pad={{ horizontal: 'medium' }}
        // width={size === 'small' ? '100%' : '529px'}
      >
        {favoriteList.length === 0 && (
          <div>
            <p
              // textAlign="center"
              // size="17px"
              // margin={{ vertical: 'medium', right: 'medium' }}
              style={{ lineHeight: "30px", fontWeight: "bold" }}
            >
              Du hast noch keine Medien ausgewählt. Klicke auf das Sternsymbol,
              um sie auf Deine Merkliste zu setzen
            </p>
          </div>
        )}
        <div>
          <ul>
            {favoriteList && favoriteList.length
              ? favoriteList.map((media: any) => {
                  return (
                    <ListItem
                      className="mb-3"
                      key={media.id}
                      // to={articleLinkWithName(media.id, media.name)}
                    >
                      <FavoriteListCard
                        {...media}
                        handleCopyFavoritItem={handleCopyArticleToClipboard}
                        handleRemoveFavoriteItem={removeFromFavorit}
                        // handleShareFavoriteList={handleShareFavoriteList}
                        handleOpenFavoriteListItem={handleOpenFavoriteListItem}
                      />
                    </ListItem>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ className: "text-sm", duration: 9000 }}
      />
    </div>
  );
};

export default FavoriteList;

import { Fragment, useState } from "react";
import { Link, useSubmit } from "@remix-run/react";
import { Transition, Popover } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import {
  StarIcon,
  // BellIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import useLocalStorageState from "use-local-storage-state";
import LearnPlatformLogo from "~/assets/images/logomain.png";
import { classNames } from "~/utils";
import theme from "~/theme/default";
import { NavigationFlyoutMenu, NavigationFlyoutMobileMenu } from "~/screens";
import { Slideover } from "~/components";
import FavoriteList from "./favorite_list";
import { SearchBox } from "~/shared";
import type { LinksData } from "~/api/get_static_data.server";
// const userNavigation = [
//   { name: "Profile", href: "#" },
//   { name: "Settings", href: "#" },
//   { name: "Sign out", href: "#" },
// ];
// const user = {
//   name: "Debbie Lewis",
//   handle: "deblewis",
//   email: "debbielewis@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
// };

// const HeaderSectionBasedOnLocation = ({
//   location,
//   section,
// }: {
//   location: string;
//   section: "Logosection" | "SearchSection";
// }): JSX.Element | null => {
//   if (location === "/inklusion" && section === "Logosection") return null;
//   if (location === "/media/home") return null;
//   let COMPONENT: { Logosection: JSX.Element; SearchSection: JSX.Element } = {
//     Logosection: (
//       <div className="flex-shrink-0">
//         <Link to="/media/home">
//           <img className="h-10 w-auto" src={LearnPlatformLogo} alt="Workflow" />
//         </Link>
//       </div>
//     ),
//     SearchSection: (
//       <div className="flex flex-1 justify-center lg:justify-end">
//         <div className="w-full px-2 lg:px-6">
//           <label htmlFor="search" className="sr-only">
//             Search projects
//           </label>
//           <div className="relative text-indigo-200 focus-within:text-gray-400">
//             <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
//               {/* <SearchIcon className="h-5 w-5" aria-hidden="true" /> */}
//             </div>
//             <input
//               id="search"
//               name="search"
//               className="block w-full rounded-md border border-transparent bg-indigo-400 bg-opacity-25 py-2 pl-10 pr-3 leading-5 text-indigo-100 placeholder-indigo-200 focus:bg-white focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
//               placeholder="Search projects"
//               type="search"
//             />
//           </div>
//         </div>
//       </div>
//     ),
//   };

//   return COMPONENT[section];
// };

const Header = ({ links }: { links: Array<LinksData> }) => {
  // const { pathname } = useLocation();
  const { button } = theme;
  const { base, navBar } = button;
  const [favoriteListOpen, setFavoriteList] = useState(false);
  const [favoriteList] = useLocalStorageState("favoriteList", {
    defaultValue: [],
  });
  const submit = useSubmit();
  const [query, setSearchQuery] = useState("");
  const handleShareFavoriteList = async () => {
    const listIDs = favoriteList.map((listitem: any) => listitem.id);
    let formData = new FormData();
    formData.set("favArticleIds", `${listIDs}`);
    // console.log("submit function", favorit);
    submit(formData, { method: "post", action: "/" });
  };
  // console.log("width", typeof window !== "undefined" ? window.innerHeight : "");

  return (
    <Popover
      id="header_wrapper"
      className="fixed top-0 left-0 z-50 w-full bg-white shadow-[0_1px_2px_rgba(0,0,0,0.2)]"
    >
      {/* <div
        className="pointer-events-none absolute inset-0 z-30 shadow"
        aria-hidden="true"
      /> */}
      <div className="relative">
        <div className="section-wrapper flex items-center justify-between py-4  sm:px-6 md:py-2 md:px-0">
          <div>
            <Link to="/media/home">
              <span className="sr-only">RU-digital</span>
              <img
                className="h-8 w-auto sm:h-12"
                src={LearnPlatformLogo}
                alt="RU-digital-logo"
              />
            </Link>
          </div>
          <div className="-my-2  md:hidden">
            {/* Mobile Menu btn*/}
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500  focus:ring-inset focus:ring-orange-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-end">
            {/* <div className="flex items-center md:ml-12">
              <a
                href="#"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Sign in
              </a>
              <a
                href="#"
                className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              >
                Sign up
              </a>
            </div> */}
            <Popover.Group as="nav" className="flex space-x-4">
              {/* filter POPOVER */}
              <Popover>
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      // onClick={open ? () =>  : undefined}
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group rounded-full px-2.5 text-xs font-normal",
                        base,
                        navBar
                      )}
                    >
                      {" "}
                      <MagnifyingGlassIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-textColor-light",
                          "h-5 w-5  group-hover:text-blue-900"
                        )}
                        aria-hidden="true"
                      />
                      {/* <span>Lernplanfilters</span> */}
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg md:block">
                        <div className="section-wrapper py-8">
                          <SearchBox
                            onSubmit={close}
                            formAction={`/search/main?q=${query}`}
                            // defaultValue={defaultValuesMainForm.q}
                            query={query}
                            setSearchQuery={setSearchQuery}
                            isLandingPage={false}
                          />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* MERK LISTE POPOVER */}
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setFavoriteList(!favoriteListOpen);
                  }}
                  className={classNames(
                    favoriteListOpen ? "text-primary" : "text-textColor-light",

                    "group px-5 text-xs font-normal hover:text-primary",
                    base,
                    navBar
                  )}
                >
                  {" "}
                  <StarIcon
                    className={classNames(
                      favoriteListOpen
                        ? "text-primary"
                        : "text-textColor-light",
                      "-ml-0.5 mr-2 h-6 w-6 group-hover:text-primary"
                    )}
                    aria-hidden="true"
                  />
                  <span>Merkliste</span>
                  <span className="ml-2 -mr-1 h-4 w-6 rounded-xl bg-btnColor-secondary text-white">
                    {favoriteList ? favoriteList.length : ""}
                  </span>
                </button>

                <Slideover
                  width="max-w-lg"
                  dir="right"
                  open={favoriteListOpen}
                  toggle={setFavoriteList}
                  size={"small"}
                  parentSelector="#header_wrapper"
                >
                  <div className="opacity-1 flex h-full w-full flex-col rounded-l-3xl bg-white pt-2 shadow-xl blur-none">
                    <div className="flex min-h-0 flex-1 flex-col  py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-full bg-primary text-white focus:outline-none focus:ring-2 focus:ring-btnColor-secondary"
                              onClick={() => setFavoriteList(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              onClick={handleShareFavoriteList}
                              type="submit"
                              className="ml-1 inline-flex items-center border-none pr-2  text-sm font-medium leading-4 text-primary  hover:text-btnColor-secondary focus:outline-none focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2"
                            >
                              <ArrowUpOnSquareIcon
                                className="-ml-0.5 mr-2 h-6 w-6"
                                aria-hidden="true"
                              />
                              Teilen
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 overflow-y-scroll px-4 sm:px-6">
                        <FavoriteList setFavOpen={setFavoriteList} />
                      </div>
                    </div>
                  </div>
                </Slideover>
              </div>
              {/* Notification LISTE POPOVER */}
              {/* <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-textColor-light",
                        base,
                        navBar,
                        "group px-2 text-xs font-normal"
                      )}
                    >
                      <span className="relative inline-block">
                        <BellIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-textColor-light",
                            "h-6 w-6 group-hover:text-textColor-light"
                          )}
                          aria-hidden="true"
                        />
                        <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
                      </span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg md:block">
                        <div className="mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                          Test content
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover> */}
              {/* Avatar  POPOVER */}
              {/* <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full text-sm text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block py-2 px-4 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu> */}
              {/* navBar floating  POPOVER */}
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        !open ? "bg-btnColor-navBar" : "bg-btnColor-secondary",
                        base,
                        "rounded-full px-2.5"
                      )}
                    >
                      <Bars3Icon
                        className={classNames(
                          !open ? "text-gray-900" : " text-white",
                          "h-6 w-6 rounded-full"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 top-full z-50 hidden transform border-t bg-white shadow-lg md:block">
                        <NavigationFlyoutMenu links={links} />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus={false}
          className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
        >
          {({ close }) => {
            return <NavigationFlyoutMobileMenu links={links} close={close} />;
          }}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
// <Disclosure
//   as="nav"
//   className="fixed top-0 left-0 z-50 w-full flex-shrink-0"
//   style={{
//     background:
//       pathname === "/inklusion"
//         ? "#ffffff"
//         : "radial-gradient(circle at 50% 89%, #2fc0d3, #325cbd 0%)",
//   }}
// >
//   {({ open }) => (
//     <>
//       <div className="mx-auto max-w-8xl px-2 sm:px-4 lg:px-8">
//         <div className="relative flex h-16 items-center justify-between">
//           {/* Logo section */}
//           <div className="flex items-center px-2 lg:px-0 xl:w-64">
//             <HeaderSectionBasedOnLocation
//               section="Logosection"
//               location={pathname}
//             />
//           </div>

//           {/* Search section */}
//           <HeaderSectionBasedOnLocation
//             section="SearchSection"
//             location={pathname}
//           />
//           <div className="flex lg:hidden">
//             {/* Mobile menu button */}
//             <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-400 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
//               <span className="sr-only">Open main menu</span>
//               {open ? (
//                 <XIcon className="block h-6 w-6" aria-hidden="true" />
//               ) : (
//                 <MenuAlt1Icon
//                   className="block h-6 w-6"
//                   aria-hidden="true"
//                 />
//               )}
//             </Disclosure.Button>
//           </div>
//           {/* Links section */}
//           <div className="hidden lg:block lg:w-80">
//             <div className="flex items-center justify-end">
//               <div className="flex">
//                 {navigation.map((item) => (
//                   <a
//                     key={item.name}
//                     href={item.href}
//                     className="rounded-md px-3 py-2 text-sm font-medium text-primary hover:text-white"
//                     aria-current={item.current ? "page" : undefined}
//                   >
//                     {item.name}
//                   </a>
//                 ))}
//               </div>
//               {/* Profile dropdown */}
//               {/* <Menu as="div" className="relative ml-4 flex-shrink-0">
//                 <div>
//                   <Menu.Button className="flex rounded-full bg-indigo-700 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700">
//                     <span className="sr-only">Open user menu</span>
//                     <img
//                       className="h-8 w-8 rounded-full"
//                       src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
//                       alt=""
//                     />
//                   </Menu.Button>
//                 </div>
//                 <Transition
//                   as={Fragment}
//                   enter="transition ease-out duration-100"
//                   enterFrom="transform opacity-0 scale-95"
//                   enterTo="transform opacity-100 scale-100"
//                   leave="transition ease-in duration-75"
//                   leaveFrom="transform opacity-100 scale-100"
//                   leaveTo="transform opacity-0 scale-95"
//                 >
//                   <Menu.Items
//                     className="absolute right-0 z-10 mt-2 w-48
//                   origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
//                   >
//                     {userNavigation.map((item) => (
//                       <Menu.Item key={item.name}>
//                         {({ active }) => (
//                           <a
//                             href={item.href}
//                             className={classNames(
//                               active ? "bg-gray-100" : "",
//                               "block px-4 py-2 text-sm text-gray-700"
//                             )}
//                           >
//                             {item.name}
//                           </a>
//                         )}
//                       </Menu.Item>
//                     ))}
//                   </Menu.Items>
//                 </Transition>
//               </Menu> */}
//             </div>
//           </div>
//         </div>
//       </div>

//       <Disclosure.Panel className="lg:hidden">
//         <div className="space-y-1 px-2 pt-2 pb-3">
//           {navigation.map((item) => (
//             <Disclosure.Button
//               key={item.name}
//               as="a"
//               href={item.href}
//               className={classNames(
//                 item.current
//                   ? "bg-indigo-800 text-white"
//                   : "text-indigo-200 hover:bg-indigo-600 hover:text-indigo-100",
//                 "block rounded-md px-3 py-2 text-base font-medium"
//               )}
//               aria-current={item.current ? "page" : undefined}
//             >
//               {item.name}
//             </Disclosure.Button>
//           ))}
//         </div>
//         {/* <div className="border-t border-indigo-800 pt-4 pb-3">
//           <div className="space-y-1 px-2">
//             {userNavigation.map((item) => (
//               <Disclosure.Button
//                 key={item.name}
//                 as="a"
//                 href={item.href}
//                 className="block rounded-md px-3 py-2 text-base font-medium text-indigo-200 hover:bg-indigo-600 hover:text-indigo-100"
//               >
//                 {item.name}
//               </Disclosure.Button>
//             ))}
//           </div>
//         </div> */}
//       </Disclosure.Panel>
//     </>
//   )}
// </Disclosure>

/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useLocation, Link } from "@remix-run/react";
import HeroImage from "~/assets/images/img-hero-start.png";
import Logo2 from "~/assets/images/eulen_fisch_logo.png";
import curvbackground from "~/assets/images/start-page-wave.svg";
// import Logo from "../assets/images/logomain.png";
import dotsBackground from "~/assets/images/inkusion/bg-dot-pattern-lightgray.png";
// import MediaIcon from "~/assets/icons/folder-media-white.svg";
// import Meeting from "~/assets/icons/team-meeting-white.svg";
// import Book from "~/assets/icons/open-book-white.svg";
import theme from "~/theme/default";

import { classNames } from "../utils";
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  // PencilIcon,
} from "@heroicons/react/20/solid";

// for now we hide the const mediaLinks bellpw for the first release
const mediaLinks: any = [
  // {
  //   name: "Medien",
  //   description:
  //     "Get a better understanding of where your traffic is coming from.",
  //   href: "/media/home",
  //   src: MediaIcon,
  // },
  // {
  //   name: "Fortbildungen",
  //   description: "Speak directly to your customers in a more meaningful way.",
  //   href: "#",
  //   src: Meeting,
  // },
  // {
  //   name: "Fachartikel",
  //   description: "Your customers' data will be safe and secure.",
  //   href: "#",
  //   src: Book,
  // },
];

interface IPageIntro {
  logo: boolean;
  title: string;
  subtitle?: string;
  searchBox?: React.ReactElement;
  mainImage?: any;
  size: string;
  isLandingPage?: boolean;
}
const PageIntro: React.FC<IPageIntro> = ({
  logo,
  title,
  subtitle,
  searchBox,
  mainImage,
  size,
  isLandingPage = false,
}) => {
  const location = useLocation();
  const { button } = theme;
  const { base } = button;

  const getHeightBasedOnScreenType = () => {
    if (isLandingPage) {
      return "h-[600px]";
    }

    if (
      location.pathname.includes("/method_glossar") ||
      location.pathname.includes("/impressum") ||
      location.pathname.includes("/datenschutz") ||
      location.pathname.includes("/partners")
    ) {
      return "h-[450px]";
    }

    return "h-[350px]";
  };

  return (
    <div
      className={classNames(
        "relative flex  w-full bg-cover bg-bottom pt-0",
        getHeightBasedOnScreenType()
      )}
      style={{
        backgroundImage: `linear-gradient(to right, #153d71, #005283, #006792, #007c9e, #1f91a7)`,
        // height: getHeightBasedOnScreenType(),
      }}
    >
      <div
        className="flex w-full"
        style={
          isLandingPage
            ? {
                backgroundImage: `url(${dotsBackground})`,
                backgroundSize: "43%",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
              }
            : undefined
        }
      >
        <div
          className="z-10 flex w-full justify-center"
          style={{
            // border: '1px solid red',
            backgroundImage: `url(${curvbackground})`,
            backgroundSize: "100%",
            backgroundPosition: "100% 100%",
            backgroundRepeat: "no-repeat",
            zIndex: 10,
            // marginBottom: "45px",
            // // width: '100%',
            // maxHeight: size === 'small' ? '450px' : undefined,
          }}
        >
          <div className="section-wrapper mx-2 flex w-full flex-col md:flex-row">
            <div
              className={classNames(
                (!subtitle && !searchBox) ||
                  location.pathname.includes("/help_center")
                  ? "justify-center "
                  : "justify-start",
                "md:1/2 flex w-full flex-col items-center md:items-start"
              )}
            >
              {/* {isLandingPage && size !== "small" && (
              <div>
                <Link to="/">
                  <img src={Logo} className="h-full w-full object-contain" />
                </Link>
              </div>
            )} */}
              <div
                className={classNames(
                  isLandingPage ? "" : "mt-8",
                  "pt-0 md:pt-8 md:pb-3"
                )}
              >
                {isLandingPage && (
                  <div className="mt-6 mb-6 flex md:mb-14">
                    {mediaLinks.map((mediaItem: any) => (
                      <span className="mr-2" key={mediaItem.name}>
                        <Link
                          to={mediaItem.href}
                          // type="Link"
                          className={classNames(
                            mediaItem.href === location.pathname
                              ? "bg-textColor-primary"
                              : "",
                            "inline-flex items-center rounded-[50px] border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-textColor-primary focus:bg-textColor-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 md:px-6 md:py-4 md:text-sm"
                          )}
                        >
                          <img
                            src={mediaItem.src}
                            className="-ml-1 mr-2 h-3 w-3 text-white md:mr-3 md:h-6 md:w-6"
                            aria-hidden="true"
                          />
                          {mediaItem.name}
                        </Link>
                      </span>
                    ))}
                  </div>
                )}
                <h1
                  className={classNames(
                    "text-left text-2xl font-bold text-white md:text-3xl lg:text-[42px]"
                    // location.pathname.includes("/method_glossar")
                    //   ? "md:mb-36"
                    //   : ""
                  )}
                >
                  {title}
                </h1>
              </div>
              {subtitle && (
                <div>
                  <p className="max-w-full py-1 pt-2 text-left text-[18px] text-white sm:text-base md:max-w-lg md:pb-5">
                    {subtitle}
                  </p>
                </div>
              )}
              {isLandingPage && (
                <span className="mr-2 hidden text-center md:block">
                  <Link
                    to="search/main"
                    // type="Link"
                    className={classNames(
                      base,
                      "rounded-none border-b border-b-white text-sm text-white hover:border-b-btnColor-primary"
                    )}
                  >
                    Alle Medien entdecken
                    <ArrowRightIcon
                      className="ml-2 h-6 w-6 text-white hover:text-btnColor-primary"
                      aria-hidden="true"
                    />
                  </Link>
                </span>
              )}
            </div>
            {/* MOBILE FILTER START PAGE */}
            <div className="mt-5 flex w-full flex-col md:hidden">
              {searchBox && (
                <div className="z-50 flex w-full items-center  rounded-[20px] bg-white px-2 shadow-card">
                  <div className="felx mr-2 w-full items-center">
                    {searchBox}
                  </div>
                </div>
              )}
              <div className="flex">
                <img
                  className={classNames(
                    "h-[20rem] w-full object-contain md:object-scale-down",
                    isLandingPage ? "-mt-10" : ""
                  )}
                  src={mainImage || HeroImage}
                />
              </div>
            </div>
            {/* END MOBILE FILTER START PAGE */}
            <div className="relative  hidden w-full md:flex md:flex-col">
              <img
                className={classNames(
                  isLandingPage
                    ? "h-76 md:h-auto lg:h-[390px] xl:h-[600px]"
                    : "h-64 md:h-full",
                  "w-full object-contain md:object-scale-down"
                )}
                src={mainImage || HeroImage}
              />
              {logo && (
                <div className="absolute bottom-32 right-24 hidden md:block">
                  <img src={Logo2} className="h-auto w-32 object-contain" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-14 z-10 hidden  w-full md:bottom-0 md:block">
          {searchBox && (
            <div className="mx-auto w-full rounded-[20px] p-2 md:bg-white md:px-5 md:shadow-card lg:max-w-[calc(100%_-_420px)] 2xl:max-w-7xl">
              {searchBox}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageIntro;
// Type: Linear
// Angle: 90˚
// Opacity: 100%

// Color Stop 1
// Color: RGB(21, 61, 113)
// Position: 0%

// Color Stop 2
// Color: RGB(31, 145, 167)
// Position: 100%
// Type: Linear
// Angle: 90˚
// Opacity: 100%

// Color Stop 1
// Color: #153D71
// Position: 0%

// Color Stop 2
// Color: #1F91A7
// Position: 100%

import { useEffect, useState } from "react";
import { useLocation, useParams } from "@remix-run/react";
import Header from "./header";
import PageIntro from "./page-intro-wrapper";
import Footer from "./footer";
import { SearchBox } from "~/shared";
import { classNames, copyToClipBoard, GetScreenSize } from "~/utils";
import InklusionIntroImage from "./inklusion_background_image";
import searchBgImage from "~/assets/images/search/search-bg.svg";
import { getClientSideConfig } from "~/utils";
import toast from "react-hot-toast";
import type { LinksData } from "~/api/get_static_data.server";

export default function Layout({
  children,
  title,
  subtitle,
  links,
  isLandingPage,
  hasPageIntro = true,
  hasLogo,
  bookmarktToken,
}: {
  children: any;
  title: string;
  subtitle: string;
  isLandingPage: boolean;
  id: string;
  hasLogo: false;
  hasPageIntro?: boolean;
  bookmarktToken?: { data: string };
  links: Array<LinksData>;
}) {
  const size = GetScreenSize();
  const { pathname } = useLocation();
  const { articleId, methodId } = useParams();
  const [query, setSearchQuery] = useState("");
  const isDetailsPpage = articleId || methodId;
  const formAction = query ? `/search/main?q=${query}` : `/search/main`;

  useEffect(() => {
    if (bookmarktToken && bookmarktToken.data) {
      // console.log("token", bookmarktToken);
      const isCopied = copyToClipBoard(
        `${getClientSideConfig.SHARELINKURL}/media/favorites_article/${bookmarktToken.data}`
      );
      if (isCopied) {
        toast.success(
          "Die Merkliste wurde in die Zwischenablage kopiert. Sie können den Link nun teilen."
        );
      }
    }
  }, [bookmarktToken]);
  return (
    <>
      <Header links={links} />
      <main
        id="scrollArea"
        // ref={scrollablePageDiv}
        // style={{ height: "100vh", border: "4px solid orange" }}

        className="mt-[40px] mb-20 flex-grow"
      >
        {hasPageIntro && (
          <PageIntro
            size={size}
            isLandingPage={isLandingPage}
            title={title}
            subtitle={subtitle}
            logo={hasLogo}
            searchBox={
              isLandingPage && pathname !== "/inklusion" ? (
                //@ts-ignore
                <SearchBox
                  formAction={formAction}
                  // defaultValue={defaultValuesMainForm.q}
                  query={query}
                  setSearchQuery={setSearchQuery}
                  isLandingPage={true}
                />
              ) : undefined
            }
          />
        )}
        {pathname === "/inklusion" && (
          <InklusionIntroImage size={size} title={title} subtitle={subtitle} />
        )}
        {(pathname.includes("/search/main") ||
          pathname.includes(`method_glossar/${methodId}`)) && (
          <div className="relative -z-10 hidden h-full w-full flex-col pt-0 md:flex">
            <div
              className={classNames(
                "absolute  w-full",
                isDetailsPpage ? "top-5" : "top-24"
              )}
            >
              <div className="">
                <img
                  alt=""
                  className="w-full object-bottom"
                  src={searchBgImage}
                />
              </div>
            </div>
          </div>
        )}
        {pathname.includes("/themenschwerpunkte") && (
          <div className="relative -z-10 hidden h-full w-full flex-col pt-16 md:flex">
            <div className={classNames("absolute top-6 w-full")}>
              <div className="">
                <img
                  alt=""
                  className="w-full object-bottom"
                  src={searchBgImage}
                />
              </div>
            </div>
          </div>
        )}
        {children}
      </main>
      <Footer isLandingPage={isLandingPage} links={links} />
    </>
  );
}

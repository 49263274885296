import {
  useCatch,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  useActionData,
  useLocation,
  useTransition,
  useLoaderData,
  Link
  // useFetcher,
  // PrefetchPageLinks,
} from "@remix-run/react";
import { json } from "@remix-run/node";
import { useEffect, useState } from "react";
import type { ErrorBoundaryComponent } from "@remix-run/node";
import type { ActionFunction } from "@remix-run/node";
import { ErrorBoundaryLayoutComponent } from "~/shared";
import { ScrollRestoration } from "~/hooks/use_scroll_restration";
// import UseTheme from "./hooks/use_dark_mode";
// import { themeSessionResolver } from "~/session.server";
import {
  getStaticData,
  getLinks,
  getWeiterFilterData,
  getFilterData,
  getTags,
  getAuthers } from
"~/api";
import { createBookmarktList } from "~/api";
import Layout from "./layout";
// import refreshClockwise from "~/assets/icons/refresh-clockwise.svg";
import type {
  LinksFunction,
  V2_MetaFunction,
  LoaderFunction } from
"@remix-run/node";
import lpOgImage from "~/assets/images/og_image.jpg";
import tailwindStylesheetUrl from "./styles/main.css";
import theme from "~/theme/default";
// import { getUser } from "./session.server";
import { useMatchesData } from "~/useMatchesData";
import type { LinksData, PageStaticData } from "~/api/get_static_data.server";
import { fetchInclusionData } from "~/api";
import { classNames, getDefaultFormData } from "./utils";
import { gdprConsent } from "~/cookies.server.";
import { CookiesBanner, GoogleAnalytics, ExposeAppConfig } from "~/screens";
import config from "~/config.server";
import * as gtag from "~/utils/gta_client";

export type LoaderData = {
  themeSession?: any | null;
  static: Array<PageStaticData>;
  filterData?: any;
  tagsList?: any;
  mainFilterData?: any;
  authors?: any;
  defaultValuesMainForm: any;
  links: Array<LinksData>;
  inklusionData: {
    title: String;
    subtitle: string;
  };
  gdprConsent: "all" | "necessary";
  ENV: {
    APIURL?: string;
    BASEURL?: string;
    GOOGLE_ANALYTICS_ID?: string;
    SHARELINKURL?: string;
    ISPRODUCTION: boolean;
  };
};

export const action: ActionFunction = async ({ context, request }) => {
  const formData = await request.formData();
  const ids = formData.get("favArticleIds");

  const favorit = {
    bookmarkList: {
      //@ts-ignore
      mediumIds: ids?.split(",")
    }
  };
  // console.log("bookmarktToken", favorit);
  const bookmarktToken = await createBookmarktList(favorit);
  // console.log("bookmarktToken", bookmarktToken);

  return json({ data: bookmarktToken });
};
export const loader: LoaderFunction = async ({ context, request }) => {
  // console.log("loader processprocess", config);
  const url = new URL(request.url);

  const pageIntrodata = getStaticData(url.pathname);
  const links = getLinks();
  const { title, subtitle } = await fetchInclusionData();
  const { filters: filterData } = await getWeiterFilterData();
  const { filters: mainFilterData } = await getFilterData();
  const { tags: tagsList } = await getTags({ q: "" });
  const { authors } = await getAuthers({ q: "" });
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await gdprConsent.parse(cookieHeader)) || {};

  const defaultValuesMainForm = getDefaultFormData(
    mainFilterData,
    filterData,
    tagsList,
    authors,
    url
  );
  // const finalData = {
  //   static: pageIntrodata[0],
  //   data,
  // };
  // const { getTheme } = await themeSessionResolver(request);

  // const query = url.searchParams.get("search")
  //   ? url.searchParams.get("search")
  //   : [];
  // let link = `/search/main?query=${query}&media_kind_ids=${[]}&subject_ids=${[]}&media_competence_ids=${[]}&niveau_ids=${[]}&extent_ids=${[]}&special_ids=${[]}&licence_ids=${[]}&subject_area_ids=${[]}&educational_application_areas_ids=${[]}&tag_ids=${[
  //   "",
  // ]}`;
  // console.log("calling action======", mainFilterData);
  // if (typeof query === "string") {
  //   return redirect(link);
  // }

  return json<LoaderData>(
    {
      // process.env is just avaliable on the server and we need it on client
      ENV: {
        APIURL: config.APIURL,
        BASEURL: config.BASEURL,
        GOOGLE_ANALYTICS_ID: config.GOOGLE_ANALYTICS_ID,
        SHARELINKURL: config.SHARELINKURL,
        ISPRODUCTION: config.BASEURL === "https://api.ru-digital.de"
      },
      static: pageIntrodata,
      defaultValuesMainForm,
      links,
      inklusionData: {
        title,
        subtitle
      },
      filterData,
      mainFilterData,
      tagsList,
      authors,
      gdprConsent: cookie.gdprConsent
      // themeSession: getTheme(),
    }
    // {
    //   headers: {
    //     // max-age controls the browser cache
    //     // s-maxage controls a CDN cache
    //     "Cache-Control": "public, max-age=30, s-maxage=86400",
    //   },
    // }
  );
};

export const unstable_shouldReload = () => false;

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: tailwindStylesheetUrl }];
};

export const meta: V2_MetaFunction = () => {
  return [
  // {
  //   charset: "utf-8",
  //   title: "ru-digital - Der Guide für deinen Religionsunterricht",
  //   viewport: "width=device-width,initial-scale=1",
  // },
  {
    property: "og:title",
    content: "ru-digital - Der Guide für deinen Religionsunterricht"
  },
  {
    name: "description",
    content:
    "ru-digital ist dein digitaler Begleiter und Resource für die Planung und Durchführung von Religionsunterricht."
  },
  { property: "og:image", content: lpOgImage },
  {
    property: "name",
    content:
    "ru-digital ist dein digitaler Begleiter und Resource für die Planung und Durchführung von Religionsunterricht."
  }];

};

// type LoaderData = {
//   user: Awaited<ReturnType<typeof getUser>>;
// };

// export const loader: LoaderFunction = async ({ request }) => {
//   return json<LoaderData>({
//     user: await getUser(request),
//   });
// };

function Document({
  children,
  title,
  theme





}: // ssrTheme,
{children: any;title?: any;theme?: any;}) {// <html lang="en" data-theme={theme}>

  return (
    <html lang="de" data-theme={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />

        <Links />
      </head>
      {/* min-h-screen */}
      <body className="flex min-h-screen flex-col bg-white">
        {/* @ts-ignore */}
        {children}
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>);

}
export default function AppWithProviders() {
  // const { themeSession } = useLoaderData<LoaderData>();

  return <App />;
}

export function App() {
  const hasWindow = typeof window !== "undefined";
  // if (hasWindow) {
  //   //@ts-ignore
  //   console.log("config.process.env", window.ENV);
  // }
  const [showPendingSpinner, setShowPendingSpinner] = useState(false);
  const location = useLocation();
  const data = useMatchesData("/", location.pathname);
  const { inklusionData, ENV } = useLoaderData<LoaderData>();
  let transition = useTransition();
  const actionData = useActionData<typeof action>();

  const { isLandingPage, title, subtitle, id, hasPageIntro, hasLogo } =
  data?.item;
  const gtaConsent = data?.gdprConsent;

  useEffect(() => {
    if (ENV.GOOGLE_ANALYTICS_ID?.length) {
      // console.log("ENV.GOOGLE_ANALYTICS_ID?.length", ENV.GOOGLE_ANALYTICS_ID);
      gtag.pageview(location.pathname, ENV.GOOGLE_ANALYTICS_ID);
    }
  }, [location, ENV.GOOGLE_ANALYTICS_ID]);

  // //@ts-ignore
  useEffect(() => {
    let timer = setTimeout(() => {
      if (hasWindow) {
        setShowPendingSpinner(transition.state !== "idle");
      }
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [transition.state, hasWindow]);
  //theme={clientTheme} ssrTheme={Boolean(serverTheme)}
  return (
    <Document>
      <Layout
        links={data?.links}
        bookmarktToken={actionData}
        hasPageIntro={hasPageIntro}
        isLandingPage={isLandingPage}
        title={location.pathname === "/inklusion" ? inklusionData.title : title}
        hasLogo={hasLogo}
        subtitle={
        location.pathname === "/inklusion" ? inklusionData.subtitle : subtitle}

        id={id}>

        {showPendingSpinner &&
        // <Modal open={true} planDialgo>
        <div className="fixed top-1/2 left-1/2 z-20  -translate-x-1/2 -translate-y-1/2 transform-gpu  rounded-md bg-black p-14 text-center opacity-60">
            <div
            aria-label="Loading..."
            role="status"
            className="flex justify-center">

              <svg className="h-8 w-8 animate-spin" viewBox="3 3 18 18">
                <path
                className="fill-yellow-400"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z">
              </path>
                <path
                className="fill-gray-800"
                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z">
              </path>
              </svg>
            </div>
            <div>
              <p className="mt-2 text-center font-bold text-white">
                Daten werden geladen...
              </p>
            </div>
          </div>
        // </Modal>
        }
        <Outlet context={{ showPendingSpinner }} />
        {gtaConsent || gtaConsent === false ? null : <CookiesBanner />}
      </Layout>
      <ExposeAppConfig appConfig={ENV} nonce="" />
      <GoogleAnalytics
        isProduction={ENV.ISPRODUCTION}
        googleAnalyticsId={ENV.GOOGLE_ANALYTICS_ID}
        analytic={gtaConsent === "all"} />

    </Document>);

}

export function CatchBoundary() {
  const { button } = theme;
  const { base, color } = button;
  const caught = useCatch();
  // console.log("🚀 ~ file: root.tsx:267 ~ CatchBoundary ~ caught:", caught);
  return (
    <html className="h-full" lang="de">
      <head>
        <meta charSet="utf-8" />
        <title>404 Seite nicht gefunden</title>
        <Links />
      </head>
      <body className="h-full">
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-bold uppercase tracking-wide text-btnColor-secondary">
                  {caught.status} error
                </p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  {caught.statusText === "Not Found" ?
                  "Seite nicht gefunden" :
                  caught.statusText}
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Es tut uns leid. Die Seite, die du suchst, gibt es nicht.
                </p>
                <div className="mt-6">
                  <Link
                    type="button"
                    to="/media/home"
                    className={classNames(base, color.primary, "px-9")}>

                    Zur&uuml;ck zur Startseite
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </html>);

}
export const ErrorBoundary: ErrorBoundaryComponent = ({ error }) => {
  // console.log("errererererer", error);
  return (
    <Document title="Error!">
      {/* <Layout> */}
      <ErrorBoundaryLayoutComponent error={error} />
      {/* </Layout> */}
    </Document>);

};
// import { Link } from "@remix-run/react";

import { Facebook, Instagram, Youtube, Twitter } from "grommet-icons";

import Logo from "../assets/images/logomain.png";
import type { LinksData } from "~/api/get_static_data.server";
import { Link, useNavigate } from "@remix-run/react";
// import { classNames } from "../utils";
// import FooterBg from '../assets/images/bg-footer@3x.png';

type IFooterMain = {
  isLandingPage?: boolean;
  links: Array<LinksData>;
};

const FooterMain: React.FC<IFooterMain> = ({ links, isLandingPage }) => {
  const navigate = useNavigate();
  const footerLabelsColor = isLandingPage ? "#040404" : "#040404";
  // console.log("🚀 ~ file: header.tsx:78 ~ Header ~ links:", links);
  const navigation = {
    solutions: [
      { name: "Medien", href: "/media/home", isExternal: false },
      // { name: "Veranstaltungen", href: "#" },
      // { name: "Fachartikel", href: "#" },
    ],
    support: [
      // { name: "Schulpastoral", href: "#" },
      { name: "Inklusion", href: "/inklusion", isExternal: false },
      {
        name: "Themenschwerpunkte",
        href: "/themenschwerpunkte",
        isExternal: false,
      },
      { name: "Methodenglossar", href: "/method_glossar", isExternal: false },
    ],
    company: [
      { name: "Blog", href: "https://blog.ru-digital.de/", isExternal: true },
      {
        name: "Newsletter",
        href: "https://newsletter.ru-digital.de/",
        isExternal: true,
      },
      {
        name: "Hilfe Center",
        href: "https://docs.ru-digital.de/",
        isExternal: true,
      },
      {
        name: "Unterstützen & Mitmachen",
        href: "https://join.ru-digital.de",
        isExternal: true,
      },
    ],
    legal: [
      {
        name: "Über ru-digital",
        href: "https://ueber.ru-digital.de/",
        isExternal: true,
      },
      { name: "Partner & Unterstützer", href: "/partners" },
      { name: "Kontakt", href: "/contact" },
    ],
    social: [
      {
        name: "Facebook",
        icon: () => <Facebook color={footerLabelsColor} />,
        href: "https://www.facebook.com/religionsunterrichtdigital/",
      },
      {
        name: "Youtube",
        icon: () => <Youtube color={footerLabelsColor} />,
        href: "https://www.youtube.com/user/EulenfischMagazin",
      },
      {
        name: "Instagram",
        icon: () => <Instagram color={footerLabelsColor} className="stroke" />,
        href: "https://www.instagram.com/ru.digital/",
      },
      {
        name: "Twitter",
        icon: () => <Twitter color={footerLabelsColor} className="h-6 w-6" />,
        href: "https://twitter.com/rudigital2",
      },
    ],
  };

  // const linkClass = classNames(
  //   isLandingPage ? "text-white" : "text-footerLabelColor",
  //   "mt-1 text-xs font-medium  hover:text-gray-900 hover:underline"
  // );
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="section-wrapper lg:py-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-10" src={Logo} alt="Company name" />
            <p className="text-base text-gray-500">
              Der Guide für deinen Religionsunterricht.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className="text-sm text-gray-400"
                  rel="noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <ul className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      {item.isExternal ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            navigate(item.href);
                            // close();
                          }}
                          key={item.name}
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      {item.isExternal ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            navigate(item.href);
                            // close();
                          }}
                          key={item.name}
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      {item.isExternal ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            navigate(item.href);
                            // close();
                          }}
                          key={item.name}
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      {item.isExternal ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            navigate(item.href);
                            // close();
                          }}
                          key={item.name}
                          className="text-sm text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 flex justify-center border-t border-gray-200 pt-8">
          <p className="mr-1 text-base text-gray-400 xl:text-center">
            &copy; 2022 ru-digital | Eulenfisch |
          </p>

          <Link to="/datenschutz">
            <span className="mr-1 text-base text-gray-400 hover:text-gray-900 xl:text-center">
              Datenschutz |
            </span>
          </Link>
          <Link to="/impressum">
            <span className="text-base text-gray-400 hover:text-gray-900 xl:text-center">
              Impressum
            </span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;

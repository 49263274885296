/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import { useLocation, Link } from "@remix-run/react";
import HeroImage from "~/assets/images/inkusion/hero-inklusion.svg";
import dotsBackground from "~/assets/images/inkusion/hero-dot-bg.png";
import curvbackground from "~/assets/images/inkusion/wave-bg-top-gray.svg";

import { classNames } from "~/utils";

interface IInklusionIntroImage {
  title: string;
  subtitle?: string;
  src?: any;
  size: string;
}

const InklusionIntroImage: React.FC<IInklusionIntroImage> = ({
  title,
  subtitle,
  size,
}) => {
  // console.log("size", size);
  return (
    <div
      className="relative mt-6 flex h-full w-full flex-col pt-0"
      style={{
        // height: "470px",
        backgroundImage: `linear-gradient(90deg,#FFAB00 0, #FFC500 100%)`,
      }}
    >
      <div
        className="3xl:mx-auto
      3xl:max-w-8xl"
        style={{
          backgroundImage: `url(${dotsBackground})`,
          // backgroundSize: "100%",
          backgroundPosition: "100% 100%",
          backgroundRepeat: "repeat",
        }}
      >
        <div
          //md:mr-0 md:max-w-4xl  xl:max-w-6xl  2xl:max-w-9xl
          className="mx-auto flex w-full flex-col px-3
        text-center
        md:flex-row
        md:px-0
         3xl:mx-auto
         3xl:max-w-8xl"
        >
          <div className="w-full  md:w-1/2">
            <div
              // style={{ maxWidth: "calc(100% - 160px)" }}
              className="ml-auto w-full
                   md:max-w-[calc(100%_-_60px)]
                xl:max-w-[calc(100%_-_160px)]
              3xl:max-w-full
              "
            >
              <div className="w-full pt-3 xl:pt-5 2xl:mt-12 2xl:pt-0">
                <h1
                  className="max-w-sm text-center font-sans text-2xl font-bold
                   leading-[1] text-primary md:text-left  md:text-3xl lg:text-5xl
              xl:text-[74px]"
                >
                  {title}
                </h1>
              </div>
              {subtitle && (
                <div>
                  <p
                    className="max-w-full pt-1 text-center text-primary
                  sm:text-base md:pt-5 md:text-left
                  md:text-lg xl:text-2xl"
                  >
                    {subtitle}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="z-0 w-full md:w-1/2">
            <img
              className={classNames("z-0 h-full w-full object-fill")}
              src={HeroImage}
            />
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 z-40 w-full 3xl:bottom-[-100px] 3xl:z-0 3xl:h-24">
        <div className="md:-mt-20">
          <img
            className={classNames("z-50 w-full object-bottom")}
            src={curvbackground}
          />
        </div>
      </div>
    </div>
  );
};

export default InklusionIntroImage;
